<template>
<div>
    <v-dialog fullscreen persistent no-click-animation hide-overlay transition="dialog-bottom-transition" v-model="dialog">
        <v-card>
            <BarTitleDialog :title="title" @close="close" />
            <v-card-actions>
                <v-row class="">
                    <v-col>
                            <v-btn v-if="editedItem.id" @click="showDialogResendVoucher()"  x-large style="background:#d3d3d3" ><v-icon>{{ icons.mdiEmailSyncOutline  }}</v-icon> Reenviar Voucher                            </v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="blue darken-1" x-large class="mr-2" text @click="close">
                            Fechar
                        </v-btn>
                        <v-btn :loading="loading" x-large color="primary" @click="save">
                            Salvar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-text>
                <v-container>

                    <v-row no-gutters>
                        <v-col cols="3">
                          <SelectCategoryRoom :errors="errors.room_category_id" v-model="editedItem.room_category_id" @change="getVerifyRoom()" class="mr-4" />
                        </v-col>
                        <v-col offset="4" cols="3">
                          <v-switch @change="calcAccommodationValue" v-model="editedItem.with_iss" true-value="yes" false-value="not" label="Com ISS"></v-switch>
                        </v-col>
                        <v-col  cols="2">
                          <vuetify-money v-model="editedItem.accommodation_value" label="Valor da Hospedagem" disabled outlined></vuetify-money>
                        </v-col>
                    </v-row>
                    <v-row>

                    </v-row>
                    <v-row no-gutters class="">
                        <v-col cols="">
                            <v-switch v-model="editedItem.tariff_manual" true-value="yes" false-value="not" label="Tarifa Manual"></v-switch>
                        </v-col>

                        <v-col v-if="editedItem.tariff_manual=='not'" cols="3">
                            <SelectTariff :errors="errors.tariff_id"  v-model="editedItem.tariff_id" class="mr-4" @change="errors.tariff_id=null" @object="setTariffObject" />
                        </v-col>

                        <v-col v-if="editedItem.tariff_manual=='yes'" cols="3">
                            <vuetify-money :error-messages="errors.tariff_manual_value"  v-model="tariff_manual_value" outlined label="Tarifa" class="mr-4" />
                        </v-col>

                        <v-col cols="">
                            <input-date :errors="errors.check_in" v-model="editedItem.check_in" @change="createTarif()" :label="'Check-in'" :dense="false"></input-date>
                        </v-col>

                        <v-col cols="">
                            <input-date :errors="errors.check_out" v-model="editedItem.check_out" @change="createTarif()" :dense="false" :label="'Check-out'" :css="'ml-4'"></input-date>
                        </v-col>

                        <v-col>
                            <v-btn color="primary"  class="ml-4"  @click="showDialogTariffExtraordinary ">
                                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>tarifas
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="10">
                            <!-- <SelectChipMutipleRoom  v-model="editedItem.room"/> -->
                            <v-select chips multiple outlined v-model="editedItem.room" return-object :loading="loadingSelectRoom" :disabled="loadingSelectRoom" label="Quartos" :items="rooms" item-text="description" item-value="id" ></v-select>

                        </v-col>
                        <v-col>
                          <vuetify-money
                          :disabled="ablQtdRoom"
                          v-model="editedItem.qtd_room"
                          outlined
                          type="number"
                          label="Qtd. Quartos"
                          :options="{locale: 'pt-BR',length: 3, precision: 0}"
                          class="ml-4"
                        />
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="8" >
                            <AutocompleteSearchCompany :errors="errors.company_id" v-model="company" @change="changeCompany" />
                        </v-col>
                        <v-col>
                           <v-text-field v-model="requester" class="ml-4" outlined label="Solicitante"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12">
                            <TableGuest @change="errors.guest= null;errors.company_id= null"  :errors="errors.guest"/>
                        </v-col>

                    </v-row>

                    <v-row class="mt-2">
                        <v-col>
                            <v-textarea v-model="editedItem.external_tracker" rows="1" outlined label="Localizador Externo"> </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row no-gutters >
                        <v-col>
                            <v-textarea v-model="editedItem.obs" rows="6" outlined label="Observação"> </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="" no-gutters>
                        <v-col>
                            <v-textarea v-model="editedItem.justify_cancel" rows="2" outlined label="Justificativa de Cancelamento"> </v-textarea>
                        </v-col>
                    </v-row>

                </v-container>

            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- DIALOG TARIFAS EXTRAORDINARIAS -->
    <v-dialog v-model="dialogTariffExtraordinary" persistent width="600">
        <v-card>
            <BarTitleDialog title="Tarifas" @close="dialogTariffExtraordinary = false" />
            <v-card-text>
                <!-- v-for=" tariff in editedItem.extraordinary_tariff   " -->
                <v-row no-gutters v-for="(tariff, index) in extraordinary_tariff" class="mb-2" :key="index" >

                    <v-col>
                        <vuetify-money :option="{prefix:'R$'}" v-model="tariff.value" dense outlined hide-details></vuetify-money>
                    </v-col>
                    <v-col>
                        <v-text-field hide-details="" readonly class="ml-2" outlined dense :value="formatDate(tariff.date_start)" label="Data Inicio"></v-text-field>
                        <!-- <input-date css="ml-2"  v-model="tariff.date_start" :label="'Data Inicial'" :dense="true"></input-date> -->
                    </v-col>
                    <v-col>
                        <v-text-field hide-details="" readonly class="ml-2" outlined dense :value="formatDate(tariff.date_end)" label="Data Fim"></v-text-field>
                        <!-- <input-date css="ml-2"  v-model="tariff.date_end" :label="'Data Final'" :dense="true"></input-date> -->
                    </v-col>
                    <!-- <v-col cols="1">
                        <v-btn icon color="error" class="ml-2" @click="removeTariffExtraordinary(tariff)" >
                            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                    </v-col> -->
                </v-row>
                <!-- <v-row>
                    <v-col class="text-center">
                        <v-btn rounded  color="secondary" @click="addTariffExtraordinary()" >
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                            Adicionar Tarifa
                        </v-btn>
                    </v-col>
                </v-row> -->
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row>
                    <v-col class="text-right">
                        <v-btn x-large color="secondary" text class="mr-4" @click="dialogTariffExtraordinary = false">fechar</v-btn>
                        <v-btn x-large color="primary"  @click="confirmExtraordinaryTariff()">OK</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- DIALOG DESJA ENVIAR EMAIL -->
     <v-dialog
    v-model="dialogSave"
    max-width="500px"
  >
    <v-card>
      <BarTitleDialog title="enviar Voucher" @close="dialogSave=false"></BarTitleDialog>
      <v-card-title class="text-h5 text-center">
        <v-row no-gutters>
          <v-col>Deseja enviar voucher por email ?</v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-if="activeEmailVoucher">
                <v-col>
                  <v-text-field  v-model="emailVoucher" outlined  label="email" ></v-text-field>
                </v-col>
          </v-row>
        </v-expand-transition>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
          <v-btn
          :loading="loading_not"
          class="mr-4"
          x-large
          color="secondary"
          @click="loading_not = true;confirmSave()"
          >
          Não
        </v-btn>

        <v-btn
          v-if="activeEmailVoucher==false"
          x-large
          color="primary"
          :loading="loading"
          @click="activeEmailVoucher = true"
        >
          Sim
        </v-btn>
        <v-btn
        v-if="activeEmailVoucher==true"
          x-large
          color="primary"
          :loading="loading"
          @click="loading = true;confirmSave()"
        >
          ENVIAR
        </v-btn>
          </v-col>
        </v-row>


      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- DIALOG REEENVIAR VOUCHER  -->
       <v-dialog
    v-model="dialogResendVoucher"
    max-width="500px"
  >
    <v-card>
      <BarTitleDialog title="enviar Voucher" @close="dialogResendVoucher=false"></BarTitleDialog>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
                  <v-text-field   v-model="emailResendVoucherComp" outlined  label="email" ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
          <v-btn

          class="mr-4"
          x-large
          color="secondary"
          @click="dialogResendVoucher =false"
          >
          Não
        </v-btn>

        <v-btn
          x-large
          color="primary"
          :loading="loadingResendVoucher"
          :disabled="loadingResendVoucher"
          @click="sendVoucher()"
        >
          enviar
        </v-btn>

          </v-col>
        </v-row>


      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiFileExportOutline,
  mdiEmailSyncOutline,
} from '@mdi/js'

import { mapActions, mapGetters, mapMutations } from 'vuex'

import BarTitleDialog from '@/components/BarTitleDialog.vue'
import SelectCategoryRoom from '@/components/SelectCategoryRoom.vue'
import SelectChipMutipleRoom from '@/components/SelectChipMutipleRoom.vue'
import SelectTariff from '@/components/SelectTariff.vue'
import InputDate from '@/components/InputDate.vue'
import TableGuest from './TableGuest.vue'

import AutocompleteSearchCompany from '@/components/AutocompleteSearchCompany.vue'

export default {
  components: {
    BarTitleDialog,
    InputDate,
    TableGuest,
    AutocompleteSearchCompany,
    SelectCategoryRoom,
    SelectChipMutipleRoom,
    SelectTariff,
  },

  props: {
    value: {
      type: Boolean,
    },
    in_data: {
      type: Object,
    }, //recebe os dados do hospede
    title: {
      type: String,
    },
  },
  data: () => ({
    // RESEND VOUCHER
    dialogResendVoucher: false,
    emailResendVoucher: null,
    loadingResendVoucher: false,
    // END RESEND VOUCHER
    loading_not: false,
    loadingSelectRoom: false,
    activeEmailVoucher: false,
    dialogSave: false,
    extraordinary_tariff: [],
    tariff: {},
    dialogTariffExtraordinary: false,
    dialogDelete: false,
    loadingRooms: false,
    dialog: false,
    loading: false,
    rooms: [],
    guests: [],
    guest: {},
    tariffObject: {},
    tariff_manual_value: '',
    company: '',
    editedItem: {
      check_in: '',
      check_out: '',
      company_id: '',
      room: [],
      tariff_manual: 'not',
      with_iss: 'yes',
    },
    defaultItem: {
      check_in: '',
      check_out: '',
      company_id: '',
      room: [],
      tariff_manual: 'not',
      with_iss: 'yes',
    },
    errors: {
      room_category_id: null,
      tariff_id: null,
      check_in: null,
      check_out: null,
      company_id: null,
      guest: null,
      room: [],
    },
    options: {},
    tab: null,
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiFileExportOutline,
      mdiEmailSyncOutline,
    },
  }),

  watch: {
    value(val) {
      this.dialog = val
      if (val) {
        this.editedItem = this.in_data
        this.company = this.editedItem.company
        this.extraordinary_tariff = this.editedItem.extraordinary_tariff
        this.tariff_manual_value = this.editedItem.tariff_manual_value
      }
    },
    dialog(val) {
      this.$emit('input', val)
      if (!val) {
        this.close()
      }
    },
    tariff_manual_value(val) {
      this.errors.tariff_manual_value = null //limpa message de error
      this.editedItem.tariff_manual_value = val
      this.calcAccommodationValue()
    },
  },

  created() {
    
  },

  computed: {
    ...mapGetters('reserve', ['getGuests']),
    cell_phone() {
      if (this.editedItem.guest) {
        return this.editedItem.guest.cell_phone
      }
    },
    email() {
      if (this.editedItem.guest) {
        return this.editedItem.guest.email
      }
    },
    ablQtdRoom() {
      if (this.editedItem.room.length > 0) {
        return true
      }
      return false
    },
    requester: {
      get() {
        return this.editedItem.requester
      },
      set(val) {
        this.editedItem.requester = val[0].toUpperCase() + val.substring(1)
      },
    },
    emailVoucher: {
      get() {
        return this.editedItem.email_voucher
      },
      set(val) {
        this.editedItem.email_voucher = val.toLowerCase()
      },
    },
    emailResendVoucherComp: {
      get() {
        return this.emailResendVoucher
      },
      set(val) {
        this.emailResendVoucher = val.toLowerCase()
      },
    },
  },

  methods: {
    ...mapActions('reserve', ['store', 'update', 'getRooms', 'exportPdf', 'setGuests', 'verifyRoom', 'resendVoucher']),
    ...mapMutations('reserve', ['setGuests']),
  

    inicialize() {},
    getVerifyRoom() {
      this.errors.room_category_id = null //apaga a menssagem de erro

      //se checki nao tem data não fa requisicao
      if (this.editedItem.check_in == null || this.editedItem.check_in == undefined || this.editedItem.check_in == '') {
        return false
      }

      //se checkout nao tem data não fa requisicao
      if (
        this.editedItem.check_out == null ||
        this.editedItem.check_out == undefined ||
        this.editedItem.check_out == ''
      ) {
        return false
      }

      if (this.editedItem.room_category_id == null || this.editedItem.room_category_id == undefined) {
        return false
      }

      const params = {
        room_category_id: this.editedItem.room_category_id,
        check_in: this.editedItem.check_in,
        check_out: this.editedItem.check_out,
      }
      this.rooms = this.in_data.room
      
      this.verifyRoom(params).then(response => {
        
        this.rooms = [...this.rooms,...response.data, ]
      })
      
      
    },

    showDialogTariffExtraordinary() {
      this.dialogTariffExtraordinary = true
    },
    // addTariffExtraordinary(){
    //    this.extraordinary_tariff.push({ value:0, date_start:'', date_end:''})

    // },
    // removeTariffExtraordinary(tariff){
    //    const index = this.extraordinary_tariff.indexOf(tariff);
    //    this.extraordinary_tariff.splice(index ,1)

    // },
    setTariffObject(object) {
      this.tariffObject = object
      this.calcAccommodationValue()
    },

    createTarif() {
      this.errors.check_out = null
      this.errors.check_in = null

      this.getVerifyRoom()

      let checkIn = new Date(this.editedItem.check_in)
      const diffInMs = new Date(this.editedItem.check_out) - new Date(this.editedItem.check_in)
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

      let value = 0

      if (this.editedItem.tariff_manual == 'yes') {
        value = this.editedItem.tariff_manual_value
      } else {
        value = this.tariffObject.value
      }
      let tariffs = []
      let date_start = ''
      let date_end = ''
      for (let i = 0; i < diffInDays; i++) {
        date_start = checkIn.toISOString().split('T')[0]
        checkIn.setDate(checkIn.getDate() + 1) //adiciona 1 dia
        date_end = checkIn.toISOString().split('T')[0]

        tariffs.push({ value: value, date_start: date_start, date_end: date_end })
      }

      this.extraordinary_tariff = tariffs
      this.calcAccommodationValue()
    },

    calcAccommodationValue() {
      if (this.extraordinary_tariff != undefined || this.extraordinary_tariff > 0) {
        let result = this.extraordinary_tariff.reduce((acc, tariff) => {
          return acc + parseFloat(tariff.value)
        }, 0)

        //calc de iss
        if (this.editedItem.with_iss == 'yes') {
          if (result > 0) {
            let iss = result * 0.03
            result += iss
          }
        }

        this.editedItem.accommodation_value = result
      }
    },
    // calcExtraordinayTariff(){

    //     const value = this.extraordinary_tariff.reduce((acc,tariff) => {
    //       const diffInMs = new Date(tariff.date_end) - new Date(tariff.date_start)
    //       const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

    //       return acc + (diffInDays * tariff.value)
    //     },0);

    //     const days = this.extraordinary_tariff.reduce((acc,tariff) => {
    //       const diffInMs = new Date(tariff.date_end) - new Date(tariff.date_start)
    //       const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

    //       return acc + diffInDays
    //     },0);

    //     // console.log({value,days})
    //     return {value,days}

    // },
    confirmExtraordinaryTariff() {
      this.calcAccommodationValue()
      this.dialogTariffExtraordinary = false
    },
    changeCompany(company) {
      this.errors.company_id = null // retirar o error
      this.errors.guest = null // retirar o error
      if (company) {
        if(company.situation == '2'){
          this.$swal({
            confirmButtonColor: '#16b1ff',
            position: "center",
            html: '<p>' + 'Esta empresa está com o faturamento bloqueado' + '</p>',
            icon: "warning",
            title: "Atenção",
          });
        }
        if (company.tariff_id) {
          //se tem uma tarifa cadastrada na empres aplica no select tarifas
          this.editedItem.tariff_id = company.tariff_id
        }
      }
    },
    close() {
      this.dialogSave = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {
          ...this.defaultItem,
        }
      })
    },
    formatDate(date) {
      // const date = this.date
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    getExportPdf() {
      this.exportPdf(this.editedItem.id)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          // console.log(response)
        })
    },
    save() {
      if (!this.validations()) return

      this.dialogSave = true
    },
    confirmSave() {
      if (this.company) {
        this.editedItem.company_id = this.company.id
      }

      if (this.editedItem.tariff_manual == 'yes') {
        this.editedItem.tariff_id = null
      } else {
        this.editedItem.tariff_manual_value = null
      }

      if (this.extraordinary_tariff.length > 0) {
        this.editedItem.extraordinary_tariff = this.extraordinary_tariff
      }

      if (this.editedItem.id) {
        this.update(this.editedItem)
          .then(response => {
            const guest = response.data
            this.close()
            this.$emit('out_data', guest)
            this.$toast.success('Salvo com sucesso !')
            this.setGuests([]) //limpa a tabela hospedes
          })
          .catch()
          .finally(() => {
            this.loading = false
            this.loading_not = false
          })
      } else {
        this.store(this.editedItem)
          .then(response => {
            const guest = response.data
            this.close()
            this.$toast.success('Salvo com sucesso !')
            this.$emit('out_data', guest)
            this.setGuests([]) //limpa a tabela hospedes
            this.setExtraordinaryTariff()
          })
          .catch()
          .finally(() => {
            this.loading = false
            this.loading_not = false
          })
      }
    },
    validations() {
      let validate = true
      //valida a cetegoria do quarto
      if (this.editedItem.room_category_id == null || this.editedItem.room_category_id == undefined) {
        this.errors.room_category_id = 'Campo obrigatório'
        validate = false
      }
      //valida tarifa padrao
      if (this.editedItem.tariff_manual == 'not') {
        //se estiver abilitado para tarifa padrao
        if (this.editedItem.tariff_id == null || this.editedItem.tariff_id == undefined) {
          this.errors.tariff_id = 'Campo obrigatório'
          validate = false
        }
      }

      //valida tarifa manual
      if (this.editedItem.tariff_manual == 'yes') {
        //se estiver abilitado para tarifa padrao

        if (
          this.tariff_manual_value == null ||
          this.tariff_manual_value == undefined ||
          this.tariff_manual_value == 0
        ) {
          this.errors.tariff_manual_value = 'Campo obrigatório'
          validate = false
        }
      }
      //valida checkin
      if (this.editedItem.check_in == null || this.editedItem.check_in == undefined || this.editedItem.check_in == '') {
        this.errors.check_in = 'Campo obrigatório'
        validate = false
      }

      //valida data checkout
      if (
        this.editedItem.check_out == null ||
        this.editedItem.check_out == undefined ||
        this.editedItem.check_out == ''
      ) {
        this.errors.check_out = 'Campo obrigatório'
        validate = false
      }

      //valida se tem hopede ou empresa
      let guest = false
      let company = false
      if (this.getGuests.length == 0) {
        guest = true
      }
      if (this.company == null || this.company == undefined || this.company == '') {
        company = true
      }
      if (guest && company) {
        this.errors.company_id = 'Obrigatório preencher empresa ou hóspede'
        this.errors.guest = 'Obrigatório preencher empresa ou hóspede'
        validate = false
      }

      return validate
    },

    showDialogResendVoucher() {
      this.emailResendVoucher = ''
      this.dialogResendVoucher = true
    },
    sendVoucher() {
      const data = {
        reserve_id: this.editedItem.id,
        email: this.emailResendVoucher,
      }
      this.loadingResendVoucher = true
      this.resendVoucher(data)
        .then(response => {
          this.$toast.success('Email enviado com sucesso.')
          this.dialogResendVoucher = false
        })
        .finally(() => {
          this.loadingResendVoucher = false
        })
    },
  },
}
</script>

<style scoped>
.card-tab {
  min-height: 420px;
  min-width: 736px;
}

.tool_table_fam {
  height: 50px;
}
</style>
